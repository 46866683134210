import { EventEmitter } from 'events';
import { User, UserUpdatedEventArgs } from '../user';
import { Network } from '../services/network';
import { Session } from '../session';
import { SyncClient } from 'twilio-sync';
import { UriBuilder } from '../util';

export interface UsersServices {
  session: Session;
  network: Network;
  syncClient: SyncClient;
}

/**
 * @classdesc Container for known users
 * @fires Users#userUpdated
 */
class Users extends EventEmitter {

  private services: UsersServices;
  private subscribedUsers: Map<string, User>;
  private fifoStack: any;
  private fifoStackMaxLength: number;
  private userUrlPromise: Promise<string>;
  private userUrl: string;
  public readonly myself: User;

  constructor(services: UsersServices) {
    super();
    this.services = services;
    this.fifoStack = [];
    this.fifoStackMaxLength = 100;
    this.myself = new User(null, null, this.services);
    this.myself.on('updated', (args: UserUpdatedEventArgs) => this.emit('userUpdated', args));
    this.myself.on('userSubscribed', () => this.emit('userSubscribed', this.myself));
    this.myself.on('userUnsubscribed', () => {
      this.emit('userUnsubscribed', this.myself);
      this.myself._ensureFetched();
    });
    this.services = services;
    this.subscribedUsers = new Map<string, User>();

    this.userUrlPromise =
      this.services.session.getSessionLinks()
        .then((links) => {
          this.userUrl = links.usersUrl;
          return this.userUrl;
        });

    this.services.session.getMaxUserInfosToSubscribe()
      .then(maxUserInfosToSubscribe => {
          this.fifoStackMaxLength = maxUserInfosToSubscribe;
        }
      );

    this.services.session.getUsersData()
      .then(data => {
        this.myself.identity = data.identity;
        this.myself.entityName = data.user;
        return this.myself._ensureFetched();
      });
  }

  private handleUnsubscribeUser(user: User): void {
    if (this.subscribedUsers.has(user.identity)) {
      this.subscribedUsers.delete(user.identity);
    }
    let foundItemIndex = -1;
    let foundItem = this.fifoStack.find((item, index) => {
      if (item == user.identity) {
        foundItemIndex = index;
        return true;
      }
      return false;
    });
    if (foundItem) {
      this.fifoStack.splice(foundItemIndex, 1);
    }
    this.emit('userUnsubscribed', user);
  }

  private handleSubscribeUser(user: User): void {
    if (this.subscribedUsers.has(user.identity)) {
      return;
    }
    if (this.fifoStack.length >= this.fifoStackMaxLength) {
      this.subscribedUsers.get(this.fifoStack.shift()).unsubscribe();
    }
    this.fifoStack.push(user.identity);
    this.subscribedUsers.set(user.identity, user);
    this.emit('userSubscribed', user);
  }

  /**
   * Gets user, if it's in subscribed list - then return the user object from it,
   * if not - then subscribes and adds user to the FIFO stack
   * @returns {Promise<User>} Fully initialized user
   */
  async getUser(identity: string, entityName: string = null): Promise<User> {
    await this.services.session.getUsersData();
    await this.myself._ensureFetched();

    if (identity == this.myself.identity) {
      return this.myself;
    }

    let user = this.subscribedUsers.get(identity);
    if (!user) {
      if (!entityName) {
        entityName = await this.getSyncUniqueName(identity);
      }
      user = new User(identity, entityName, this.services);
      user.on('updated', (args: UserUpdatedEventArgs) => this.emit('userUpdated', args));
      user.on('userSubscribed', () => this.handleSubscribeUser(user));
      user.on('userUnsubscribed', () => this.handleUnsubscribeUser(user));
      await user._ensureFetched();
    }

    return user;
  }

  /**
   * @returns {Promise<Array<User>>} returns list of subscribed User objects {@see User}
   */
  async getSubscribedUsers(): Promise<Array<User>> {
    await this.services.session.getUsersData();
    await this.myself._ensureFetched();
    let users = [this.myself];
    this.subscribedUsers.forEach((user) => users.push(user));
    return users;
  }

  /**
   * @returns {Promise<string>} User's sync unique name
   */
  private async getSyncUniqueName(identity: string): Promise<string> {
    const url = new UriBuilder(this.userUrl).path(identity).build();
    let response = await this.services.network.get(url);
    return response.body.sync_unique_name;
  }
}

export { Users };
