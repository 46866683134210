import { EventEmitter } from 'events';
import { ParticipantDescriptor, Participant, ParticipantUpdatedEventArgs } from '../participant';
import { Logger } from '../logger';

import { Conversation } from '../conversation';

import { SyncMap, SyncClient } from 'twilio-sync';
import { Users } from './users';
import { Session } from '../session';
import { SessionError } from '../sessionerror';

const log = Logger.scope('Participants');

export interface ParticipantsServices {
  session: Session;
  syncClient: SyncClient;
  users: Users;
}

/**
 * @classdesc Represents the collection of participants for the conversation
 * @fires Participants#participantJoined
 * @fires Participants#participantLeft
 * @fires Participants#participantUpdated
 */
class Participants extends EventEmitter {

  services: ParticipantsServices;
  rosterEntityPromise: Promise<SyncMap>;

  public readonly conversation: Conversation;
  public readonly participants: Map<string, Participant>;

  constructor(conversation: Conversation, services: ParticipantsServices, participants: Map<string, Participant>) {
    super();
    this.services = services;
    this.conversation = conversation;
    this.participants = participants;
  }

  async unsubscribe(): Promise<void> {
    if (this.rosterEntityPromise) {
      let entity = await this.rosterEntityPromise;
      entity.close();
      this.rosterEntityPromise = null;
    }
  }

  subscribe(rosterObjectName: string) {
    return this.rosterEntityPromise = this.rosterEntityPromise
      || this.services.syncClient.map({ id: rosterObjectName, mode: 'open_existing' })
             .then(rosterMap => {
               rosterMap.on('itemAdded', args => {
                 log.debug(this.conversation.sid + ' itemAdded: ' + args.item.key);
                 this.upsertParticipant(args.item.key, args.item.data)
                     .then(participant => {
                       this.emit('participantJoined', participant);
                     });
               });

               rosterMap.on('itemRemoved', args => {
                 log.debug(this.conversation.sid + ' itemRemoved: ' + args.key);
                 let participantSid = args.key;
                 if (!this.participants.has(participantSid)) {
                   return;
                 }
                 let leftParticipant = this.participants.get(participantSid);
                 this.participants.delete(participantSid);
                 this.emit('participantLeft', leftParticipant);
               });

               rosterMap.on('itemUpdated', args => {
                 log.debug(this.conversation.sid + ' itemUpdated: ' + args.item.key);
                 this.upsertParticipant(args.item.key, args.item.data);
               });

               let participantsPromises = [];
               let that = this;
               const rosterMapHandler = function(paginator) {
                 paginator.items.forEach(item => { participantsPromises.push(that.upsertParticipant(item.key, item.data)); });
                 return paginator.hasNextPage ? paginator.nextPage().then(rosterMapHandler) : null;
               };

               return rosterMap
                 .getItems()
                 .then(rosterMapHandler)
                 .then(() => Promise.all(participantsPromises))
                 .then(() => rosterMap);
             })
             .catch(err => {
               this.rosterEntityPromise = null;
               if (this.services.syncClient.connectionState != 'disconnected') {
                 log.error('Failed to get roster object for conversation', this.conversation.sid, err);
               }
               log.debug('ERROR: Failed to get roster object for conversation', this.conversation.sid, err);
               throw err;
             });
  }

  async upsertParticipant(participantSid: string, data: ParticipantDescriptor): Promise<Participant> {
    let participant = this.participants.get(participantSid);
    if (participant) {
      return participant._update(data);
    }

    participant = new Participant(this.services, this.conversation, data, participantSid);
    this.participants.set(participantSid, participant);
    participant.on('updated', (args: ParticipantUpdatedEventArgs) => this.emit('participantUpdated', args));
    return participant;
  }

  /**
   * @returns {Promise<Array<Participant>>} returns list of participants {@see Participant}
   */
  getParticipants(): Promise<Array<Participant>> {
    return this.rosterEntityPromise.then(() => {
      let participants = [];
      this.participants.forEach(participant => participants.push(participant));
      return participants;
    });
  }

  /**
   * Get participant by SID from conversation
   * @returns {Promise<Participant>}
   */
  async getParticipantBySid(participantSid: string): Promise<Participant> {
    return this.rosterEntityPromise.then(() => {
      let participant = this.participants.get(participantSid);
      if (!participant) {
        throw new Error('Participant with SID ' + participantSid + ' was not found');
      }
      return participant;
    });
  }

  /**
   * Get participant by identity from conversation
   * @returns {Promise<Participant>}
   */
  async getParticipantByIdentity(identity: string): Promise<Participant> {
    let foundParticipant = null;
    return this.rosterEntityPromise.then(() => {
      this.participants.forEach(participant => {
        if (participant.identity === identity) {
          foundParticipant = participant;
        }
      });
      if (!foundParticipant) {
        throw new Error('Participant with identity ' + identity + ' was not found');
      }
      return foundParticipant;
    });
  }

  /**
   * Add a chat participant to the conversation
   * @returns {Promise<any>}
   */
  add(identity: string, attributes: any): Promise<any> {
    return this.services.session.addCommand('addMemberV2', {
      channelSid: this.conversation.sid,
      attributes: JSON.stringify(attributes),
      username: identity
    });
  }

  /**
   * Add a non-chat participant to the conversation.
   *
   * @param proxyAddress
   * @param address
   * @param attributes
   * @returns {Promise<any>}
   */
  addNonChatParticipant(proxyAddress: string, address: string, attributes: Record<string, any> = {}): Promise<any> {
    return this.services.session.addCommand('addNonChatParticipant', {
      conversationSid: this.conversation.sid,
      proxyAddress,
      attributes: JSON.stringify(attributes),
      address
    });
  }

  /**
   * Invites user to the conversation
   * User can choose either to join or not
   * @returns {Promise<any>}
   */
  invite(identity: string): Promise<any> {
    return this.services.session.addCommand('inviteMember', {
      channelSid: this.conversation.sid,
      username: identity
    });
  }

  /**
   * Remove participant from conversation by Identity
   * @returns {Promise<any>}
   */
  removeByIdentity(identity: string): Promise<any> {
    return this.services.session.addCommand('removeMember', {
      channelSid: this.conversation.sid,
      username: identity
    });
  }

  /**
   * Remove participant from conversation by sid
   * @returns {Promise<any>}
   */
  removeBySid(sid: string): Promise<any> {
    return this.services.session.addCommand('removeMember', {
      channelSid: this.conversation.sid,
      memberSid: sid
    });
  }
}

export { Participants };

/**
 * Fired when participant joined conversation
 * @event Participants#participantJoined
 * @type {Participant}
 */

/**
 * Fired when participant left conversation
 * @event Participants#participantLeft
 * @type {Participant}
 */

/**
 * Fired when participant updated
 * @event Participants#participantUpdated
 * @type {Object}
 * @property {Participant} participant - Updated Participant
 * @property {Participant#UpdateReason[]} updateReasons - Array of Participant's updated event reasons
 */
