import { EventEmitter } from 'events';

import { Logger } from './logger';
import { Session } from './session';
import { SyncClient } from 'twilio-sync';
import { isDeepEqual, parseAttributes } from './util';
import { validateTypesAsync, literal } from '@twilio/declarative-type-validator';

const log = Logger.scope('User');

interface UserState {
  identity: string;
  entityName: string;
  friendlyName: string;
  attributes: any;
  online: boolean;
  notifiable: boolean;
}

interface UserServices {
  session: Session;
  syncClient: SyncClient;
}

type SubscriptionState = 'initializing' | 'subscribed' | 'unsubscribed';

/**
 * The reason for the `updated` event being emitted by a user.
 */
type UserUpdateReason =
  | 'friendlyName'
  | 'attributes'
  | 'reachabilityOnline'
  | 'reachabilityNotifiable';

interface UserUpdatedEventArgs {
  user: User;
  updateReasons: UserUpdateReason[];
}

/**
 * Extended user information.
 * Note that `isOnline` and `isNotifiable` properties are eligible
 * for use only if the reachability function is enabled.
 * You may check if it is enabled by reading the value of {@link Client.reachabilityEnabled}.
 */
class User extends EventEmitter {

  private entity: any;
  private services: UserServices;
  private state: UserState;
  private promiseToFetch: Promise<User>;
  private subscribed: SubscriptionState;

  /**
   * @internal
   */
  constructor(identity: string, entityName: string, services: UserServices) {
    super();

    this.subscribed = 'initializing';
    this.setMaxListeners(0);

    this.services = services;

    this.state = {
      identity: identity,
      entityName: entityName,
      friendlyName: null,
      attributes: {},
      online: null,
      notifiable: null
    };
  }

  /**
   * Fired when the properties or the reachability status of the message has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link User} user - the user in question
   *     * {@link UserUpdateReason}[] updateReasons - array of reasons for the update
   * @event
   */
  public readonly updated = 'updated';

  /**
   * Fired when the client has subscribed to the user.
   *
   * Parameters:
   * 1. {@link User} `user` - the user in question
   * @event
   */
  public readonly userSubscribed = 'userSubscribed';

  /**
   * Fired when the client has unsubscribed from the user.
   *
   * Parameters:
   * 1. {@link User} `user` - the user in question
   * @event
   */
  public readonly userUnsubscribed = 'userUnsubscribed';

  /**
   * User identity.
   */
  public get identity(): string { return this.state.identity; }

  public set identity(identity: string) { this.state.identity = identity; }

  public set entityName(name: string) { this.state.entityName = name; }

  /**
   * Custom attributes of the user.
   */
  public get attributes() { return this.state.attributes; }

  /**
   * Friendly name of the user, null if not set.
   */
  public get friendlyName(): string { return this.state.friendlyName; }

  /**
   * Status of the real-time conversation connection of the user.
   */
  public get isOnline(): boolean { return this.state.online; }

  /**
   * User push notification registration status.
   */
  public get isNotifiable(): boolean { return this.state.notifiable; }

  /**
   * True if this user is receiving real-time status updates.
   */
  public get isSubscribed(): boolean { return this.subscribed == 'subscribed'; }

  // Handles service updates
  _update(key: string, value: any) {
    let updateReasons: UserUpdateReason[] = [];
    log.debug('User for', this.state.identity, 'updated:', key, value);
    switch (key) {
      case 'friendlyName':
        if (this.state.friendlyName !== value.value) {
          updateReasons.push('friendlyName');
          this.state.friendlyName = value.value;
        }
        break;
      case 'attributes':
        const updateAttributes = parseAttributes(value.value, `Retrieved malformed attributes from the server for user: ${this.state.identity}`, log);
        if (!isDeepEqual(this.state.attributes, updateAttributes)) {
          this.state.attributes = updateAttributes;
          updateReasons.push('attributes');
        }
        break;
      case 'reachability':
        if (this.state.online !== value.online) {
          this.state.online = value.online;
          updateReasons.push('reachabilityOnline');
        }
        if (this.state.notifiable !== value.notifiable) {
          this.state.notifiable = value.notifiable;
          updateReasons.push('reachabilityNotifiable');
        }
        break;
      default:
        return;
    }
    if (updateReasons.length > 0) {
      this.emit('updated', { user: this, updateReasons: updateReasons });
    }
  }

  // Fetch reachability info
  _updateReachabilityInfo(map, update) {
    if (!this.services.session.reachabilityEnabled) {
      return Promise.resolve();
    }

    return map.get('reachability')
              .then(update)
              .catch(err => { log.warn('Failed to get reachability info for ', this.state.identity, err); });
  }

  // Fetch user
  async _fetch() {
    if (!this.state.entityName) {
      return this;
    }

    this.promiseToFetch = this.services.syncClient.map({ id: this.state.entityName, mode: 'open_existing', includeItems: true })
                              .then(map => {
                                this.entity = map;
                                map.on('itemUpdated', args => {
                                  log.debug(this.state.entityName + ' (' + this.state.identity + ') itemUpdated: ' + args.item.key);
                                  return this._update(args.item.key, args.item.data);
                                });
                                return Promise.all([
                                  map.get('friendlyName')
                                     .then(item => this._update(item.key, item.data)),
                                  map.get('attributes')
                                     .then(item => this._update(item.key, item.data)),
                                  this._updateReachabilityInfo(map,
                                    item => this._update(item.key, item.data))
                                ]);
                              })
                              .then(() => {
                                log.debug('Fetched for', this.identity);
                                this.subscribed = 'subscribed';
                                this.emit('userSubscribed', this);
                                return this;
                              })
                              .catch(err => {
                                this.promiseToFetch = null;
                                throw err;
                              });
    return this.promiseToFetch;
  }

  _ensureFetched() {
    return this.promiseToFetch || this._fetch();
  }

  /**
   * Edit user attributes.
   * @param attributes New attributes.
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  public async updateAttributes(attributes: any): Promise<User> {
    if (this.subscribed == 'unsubscribed') {
      throw new Error('Can\'t modify unsubscribed object');
    }

    await this.services.session.addCommand('editUserAttributes', {
      username: this.state.identity,
      attributes: JSON.stringify(attributes)
    });

    return this;
  }

  /**
   * Update the friendly name of the user.
   * @param name New friendly name.
   */
  @validateTypesAsync(['string', literal(null)])
  public async updateFriendlyName(name: string | null): Promise<User> {
    if (this.subscribed == 'unsubscribed') {
      throw new Error('Can\'t modify unsubscribed object');
    }

    await this.services.session.addCommand('editUserFriendlyName', {
      username: this.state.identity,
      friendlyName: name
    });

    return this;
  }

  /**
   * Remove the user from the subscription list.
   * @return A promise of completion.
   */
  async unsubscribe(): Promise<void> {
    if (this.promiseToFetch) {
      await this.promiseToFetch;
      this.entity.close();
      this.promiseToFetch = null;
      this.subscribed = 'unsubscribed';
      this.emit('userUnsubscribed', this);
    }
  }
}

export {
  User,
  UserServices,
  SubscriptionState,
  UserUpdateReason,
  UserUpdatedEventArgs
};
