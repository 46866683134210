import { EventEmitter } from 'events';
import { isDeepEqual, parseAttributes, UriBuilder } from './util';
import { Logger } from './logger';

import { Conversation } from './conversation';
import { Session } from './session';
import { McsClient } from '@twilio/mcs-client';
import { Media } from './media';
import { Participant } from './participant';
import { AggregatedDeliveryReceipt } from './aggregateddeliveryreceipt';
import { validateTypesAsync, literal } from '@twilio/declarative-type-validator';
import { Network } from './services/network';
import { RestPaginator } from './restpaginator';
import { DetailedDeliveryReceipt } from './detaileddeliveryreceipt';
import { Paginator } from './interfaces/paginator';

const log = Logger.scope('Message');

interface MessageState {
  sid: string;
  index: number;
  author?: string;
  subject?: string;
  body: string;
  dateUpdated: Date;
  lastUpdatedBy: string;
  attributes: Object;
  timestamp: Date;
  type: MessageType;
  media?: Media;
  participantSid?: string;
  aggregatedDeliveryReceipt?: AggregatedDeliveryReceipt;
}

interface MessageServices {
  session: Session;
  mcsClient: McsClient;
  network: Network;
}

/**
 * The reason for the `updated` event being emitted by a message.
 */
type MessageUpdateReason =
  | 'body'
  | 'lastUpdatedBy'
  | 'dateCreated'
  | 'dateUpdated'
  | 'attributes'
  | 'author'
  | 'deliveryReceipt'
  | 'subject';

/**
 * Push notification type of a message.
 */
type MessageType = 'text' | 'media';

interface MessageUpdatedEventArgs {
  message: Message;
  updateReasons: MessageUpdateReason[];
}

/**
 * A message in a conversation.
 */
class Message extends EventEmitter {
  /**
   * Conversation that the message is in.
   */
  public readonly conversation: Conversation;
  private services: MessageServices;
  private state: MessageState;

  /**
   * @internal
   */
  constructor(conversation: Conversation, services: MessageServices, index: number, data: any) {
    super();

    this.conversation = conversation;
    this.services = services;

    this.state = {
      sid: data.sid,
      index: index,
      author: data.author == null ? null : data.author,
      subject: data.subject == null ? null : data.subject,
      body: data.text,
      timestamp: data.timestamp ? new Date(data.timestamp) : null,
      dateUpdated: data.dateUpdated ? new Date(data.dateUpdated) : null,
      lastUpdatedBy: data.lastUpdatedBy ? data.lastUpdatedBy : null,
      attributes: parseAttributes(data.attributes, `Got malformed attributes for the message ${data.sid}`, log),
      type: data.type ? data.type : 'text',
      media: (data.type && data.type === 'media' && data.media)
        ? new Media(data.media, this.services) : null,
      participantSid: data.memberSid == null ? null : data.memberSid,
      aggregatedDeliveryReceipt: data.delivery ? new AggregatedDeliveryReceipt(data.delivery) : null
    };
  }

  /**
   * Fired when the properties or the body of the message has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Message} message - the message in question
   *     * {@link MessageUpdateReason}[] updateReasons - array of reasons for the update
   */
  static readonly updated = 'updated';

  /**
   * The server-assigned unique identifier for the message.
   */
  public get sid(): string { return this.state.sid; }

  /**
   * Name of the user that sent the message.
   */
  public get author(): string { return this.state.author; }

  /**
   * Message subject. Used only in email conversations.
   */
  public get subject(): string | null { return this.state.subject; }

  /**
   * Body of the message. Null if the message is a media message.
   */
  public get body(): string {
    if (this.type === 'media') {
      return null;
    }

    return this.state.body;
  }

  /**
   * Date this message was last updated on.
   */
  public get dateUpdated(): Date { return this.state.dateUpdated; }

  /**
   * Index of the message in the conversation's messages list.
   * By design of the Conversations system, the message indices may have arbitrary gaps between them,
   * that does not necessarily mean they were deleted or otherwise modified - just that
   * messages may have some non-contiguous indices even if they are being sent immediately one after another.
   *
   * Trying to use indices for some calculations is going to be unreliable.
   *
   * To calculate the number of unread messages it is better to use the read horizon API.
   * See {@link Conversation.getUnreadMessagesCount} for details.
   */
  public get index(): number { return this.state.index; }

  /**
   * Identity of the last user that updated the message.
   */
  public get lastUpdatedBy(): string { return this.state.lastUpdatedBy; }

  /**
   * Date this message was created on.
   */
  public get dateCreated(): Date { return this.state.timestamp; }

  /**
   * Custom attributes of the message.
   */
  public get attributes(): Object { return this.state.attributes; }

  /**
   * Push notification type of the message.
   */
  public get type(): MessageType { return this.state.type; }

  /**
   * Media information (if present).
   */
  public get media(): Media { return this.state.media; }

  /**
   * The server-assigned unique identifier of the authoring participant.
   */
  public get participantSid(): string { return this.state.participantSid; }

  /**
   * Aggregated information about the message delivery statuses across all participants of a conversation..
   */
  public get aggregatedDeliveryReceipt(): AggregatedDeliveryReceipt | null { return this.state.aggregatedDeliveryReceipt; }

  _update(data) {
    let updateReasons: MessageUpdateReason[] = [];

    if ((data.text || ((typeof data.text) === 'string')) && data.text !== this.state.body) {
      this.state.body = data.text;
      updateReasons.push('body');
    }

    if (data.subject && data.subject !== this.state.subject) {
      this.state.subject = data.subject;
      updateReasons.push('subject');
    }

    if (data.lastUpdatedBy && data.lastUpdatedBy !== this.state.lastUpdatedBy) {
      this.state.lastUpdatedBy = data.lastUpdatedBy;
      updateReasons.push('lastUpdatedBy');
    }

    if (data.author && data.author !== this.state.author) {
      this.state.author = data.author;
      updateReasons.push('author');
    }

    if (data.dateUpdated &&
      new Date(data.dateUpdated).getTime() !== (this.state.dateUpdated && this.state.dateUpdated.getTime())) {
      this.state.dateUpdated = new Date(data.dateUpdated);
      updateReasons.push('dateUpdated');
    }

    if (data.timestamp &&
      new Date(data.timestamp).getTime() !== (this.state.timestamp && this.state.timestamp.getTime())) {
      this.state.timestamp = new Date(data.timestamp);
      updateReasons.push('dateCreated');
    }

    let updatedAttributes = parseAttributes(data.attributes, `Got malformed attributes for the message ${this.sid}`, log);
    if (!isDeepEqual(this.state.attributes, updatedAttributes)) {
      this.state.attributes = updatedAttributes;
      updateReasons.push('attributes');
    }

    let updatedAggregatedDelivery = data.delivery;
    let currentAggregatedDelivery = this.state.aggregatedDeliveryReceipt;
    let isUpdatedAggregateDeliveryValid = !!updatedAggregatedDelivery && !!updatedAggregatedDelivery.total &&
      !!updatedAggregatedDelivery.delivered && !!updatedAggregatedDelivery.failed && !!updatedAggregatedDelivery.read &&
      !!updatedAggregatedDelivery.sent && !!updatedAggregatedDelivery.undelivered;
    if (isUpdatedAggregateDeliveryValid) {
      if (!currentAggregatedDelivery) {
        this.state.aggregatedDeliveryReceipt = new AggregatedDeliveryReceipt(updatedAggregatedDelivery);
        updateReasons.push('deliveryReceipt');
      } else if (!currentAggregatedDelivery._isEquals(updatedAggregatedDelivery)) {
        currentAggregatedDelivery._update(updatedAggregatedDelivery);
        updateReasons.push('deliveryReceipt');
      }
    }

    if (updateReasons.length > 0) {
      this.emit('updated', { message: this, updateReasons: updateReasons });
    }
  }

  /**
   * Get the participant who is the author of the message.
   */
  async getParticipant(): Promise<Participant> {
    let participant: Participant = null;
    if (this.state.participantSid) {
      participant = await this.conversation.getParticipantBySid(this.participantSid)
        .catch(() => {
          log.debug('Participant with sid "' + this.participantSid + '" not found for message ' + this.sid);
          return null;
        });
    }
    if (!participant && this.state.author) {
      participant = await this.conversation.getParticipantByIdentity(this.state.author)
        .catch(() => {
          log.debug('Participant with identity "' + this.author + '" not found for message ' + this.sid);
          return null;
        });
    }
    if (participant) {
      return participant;
    }
    let errorMesage = 'Participant with ';
    if (this.state.participantSid) {
      errorMesage += 'SID \'' + this.state.participantSid + '\' ';
    }
    if (this.state.author) {
      if (this.state.participantSid) {
        errorMesage += 'or ';
      }
      errorMesage += 'identity \'' + this.state.author + '\' ';
    }
    if (errorMesage === 'Participant with ') {
      errorMesage = 'Participant ';
    }
    errorMesage += 'was not found';
    throw new Error(errorMesage);
  }

  /**
   * Get the delivery receipts of the message.
   */
  async getDetailedDeliveryReceipts(): Promise<DetailedDeliveryReceipt[]> {
    let paginator: Paginator<DetailedDeliveryReceipt> = await this._getDetailedDeliveryReceiptsPaginator();
    let detailedDeliveryReceipts: DetailedDeliveryReceipt[] = [];

    while (true) {
      detailedDeliveryReceipts = [...detailedDeliveryReceipts, ...paginator.items];

      if (!paginator.hasNextPage) {
        break;
      }

      paginator = await paginator.nextPage();
    }

    return detailedDeliveryReceipts;
  }

  /**
   * Remove the message.
   */
  async remove(): Promise<Message> {
    await this.services.session.addCommand('deleteMessage', {
      channelSid: this.conversation.sid,
      messageIdx: this.index.toString()
    });
    return this;
  }

  /**
   * Edit the message body.
   * @param body New body of the message.
   */
  @validateTypesAsync('string')
  async updateBody(body: string): Promise<Message> {
    await this.services.session.addCommand('editMessage', {
      channelSid: this.conversation.sid,
      messageIdx: this.index.toString(),
      text: body
    });

    return this;
  }

  /**
   * Edit the message attributes.
   * @param attributes New attributes.
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  async updateAttributes(attributes: any): Promise<Message> {
    await this.services.session.addCommand('editMessageAttributes', {
      channelSid: this.conversation.sid,
      messageIdx: this.index,
      attributes: JSON.stringify(attributes)
    });

    return this;
  }

  private async _getDetailedDeliveryReceiptsPaginator(options?: {
    pageToken?: string,
    pageSize?: number
  }): Promise<Paginator<DetailedDeliveryReceipt>> {
    let links = await this.services.session.getSessionLinks();
    let messagesReceiptsUrl = links.messagesReceiptsUrl.replace('%s', this.conversation.sid).replace('%s', this.sid);
    let url = new UriBuilder(messagesReceiptsUrl).arg('PageToken', options?.pageToken).arg('PageSize', options?.pageSize).build();
    let response = await this.services.network.get(url);

    return new RestPaginator<DetailedDeliveryReceipt>(response.body.delivery_receipts.map(x => new DetailedDeliveryReceipt(x))
      , (pageToken, pageSize) => this._getDetailedDeliveryReceiptsPaginator({ pageToken, pageSize })
      , response.body.meta.previous_token
      , response.body.meta.next_token);
  }
}

export {
  Message,
  MessageServices,
  MessageType,
  MessageUpdateReason,
  MessageUpdatedEventArgs
};
